<template>
  <div>
    <v-row>
      <v-dialog v-model="axeModal" max-width="360px">
        <v-card>
          <v-form v-on:submit.prevent="handleSavedAxe" ref="form">
            <v-card-title>
              <span class="headline">Ajouter un axe</span>
            </v-card-title>
            <v-card-text>
              <v-color-picker
                v-model="axeCreated.color"
                dot-size="25"
                swatches-max-height="200"
              ></v-color-picker>
              <v-select
                :items="axeTypes"
                label="Type"
                item-text="name"
                item-value="id"
                return-object
                clearable
                v-model="axeCreated.type"
              ></v-select>

              <v-autocomplete
                v-model="axeCreated.mesures"
                :items="mesures"
                :item-text="getMesureText"
                item-value="id"
                label="Mesures"
                multiple
                chips
                deletable-chips
                return-object
                hint="Veuillez sélectionner les mesures sur cet axe"
              >
              </v-autocomplete>

              <v-select
                :items="axeAggs"
                label="Aggregation type"
                item-text="name"
                item-value="id"
                return-object
                clearable
                v-model="axeCreated.aggregationsType"
              ></v-select>

              <v-text-field v-model="axeCreated.min" label="minimum"></v-text-field>

              <v-text-field v-model="axeCreated.max" label="maximum"></v-text-field>

              <v-checkbox
                  class="my-2"
                  hide-details
                  dense
                  outlined
                  v-model="axeCreated.display"
                  label="Afficher l'axe ?"
              ></v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-btn depressed @click="cancel">Annuler</v-btn>
              <v-spacer></v-spacer>
              <v-btn depressed color="primary" @click="handleAddedAxe">Ajouter</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-btn depressed @click.stop="axeModal = true">
        <v-icon left>add</v-icon> ajouter un axe
      </v-btn>
    </v-row>
    <v-row>
      <v-simple-table v-if="axes && axes.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Color</th>
              <th>Type</th>
              <th>Mesures</th>
              <th>Aggregations</th>
              <th>Min</th>
              <th>Max</th>
              <th>Afficher l'axe</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(axe, i) in axes" :key="i">
              <td>
                <togglerComp>
                  <template v-slot:activator>
                    <v-text-field
                        class="my-2"
                        hide-details
                        dense
                        outlined
                        readonly
                        slot="activator"
                        :background-color="axe.color.hex"
                    ></v-text-field>
                  </template>
                  <v-card max-width="200">
                    <v-card-text>
                      <v-color-picker
                          v-model="axe.color"
                          dot-size="25"
                          hide-inputs
                          hide-canvas
                          light
                      ></v-color-picker>
                    </v-card-text>
                  </v-card>
                </togglerComp>
              </td>
              <td>
                <v-select
                  :items="axeTypes"
                  label="Type"
                  item-text="name"
                  item-value="id"
                  return-object
                  clearable
                  v-model="axe.type"
                ></v-select>
              </td>
              <td>
                <v-autocomplete
                  v-model="axe.mesures"
                  :items="mesures"
                  :item-text="getMesureText"
                  item-value="id"
                  multiple
                  chips
                  deletable-chips
                  return-object
                  hint="Veuillez sélectionner les mesures sur cet axe"
                ></v-autocomplete>
              </td>
              <td>
                <v-select
                  :items="axeAggs"
                  item-text="name"
                  item-value="id"
                  return-object
                  clearable
                  v-model="axe.aggregationsType"
                ></v-select>
              </td>
              <td>
                <v-text-field
                  class="my-2"
                  hide-details
                  dense
                  outlined
                  v-model="axe.min"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  class="my-2"
                  hide-details
                  dense
                  outlined
                  v-model="axe.max"
                ></v-text-field>
              </td>
              <td>
                <v-checkbox
                    class="my-2"
                    hide-details
                    dense
                    outlined
                    v-model="axe.display"
                ></v-checkbox>
              </td>
              <td class="text-right">
                <v-icon medium @click.stop="deleteAxe(axe)">delete</v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
  </div>
</template>
<script>

import togglerComp from "./togglers/togglerComp";

export default {
  props: {
    axes: Array,
    mesures: Array,
    site: Object,
    childIdsToName: Object,
  },
  data() {
    return {
      axeTypes: ["Bars", "Lignes"],
      axeAggs: ["Moyenne", "Cumul"],
      axeModal: false,
      axeCreated: {
        display: true,
      },
    };
  },
  mounted() {
    this.axes.map(axe => {axe.display = (Object.hasOwnProperty(axe.display) ? true : (axe.display === undefined ? true : axe.display))});
  },
  components: {
    togglerComp
  },
  beforeUpdate() {},
  updated() {},
  methods: {
    getMesureText(item) {
      let name = "";
      if (item.site_id != this.site.id) {
        name += this.childIdsToName[item.site_id] + " : ";
      }
      name += item.name;
      return name;
    },
    handleAddedAxe() {
      let newAxe = Object.assign({}, this.axeCreated);
      //update current component
      this.axes.push(newAxe);
      this.axeModal = false;
      // this.axes = [...this.axes];
      this.$refs.form.reset();
      this.axeCreated = {};
      this.$emit("added", newAxe);
    },
    cancel() {
      this.$refs.form.reset();
      this.axeModal = false;
    },
    deleteAxe(axe) {
      if (confirm("Êtes-vous sûr de vouloir supprimer cet axe ?")) {
        const index = this.axes.indexOf(axe);
        if (index > -1) {
          this.axes.splice(index, 1);
        }
        // this.axes = [...this.axes];
        this.$emit("deleted", axe);
      }
    },
  },
};
</script>
